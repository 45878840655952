import Link from 'next/link'
import MainContentLayout from '../../../customLayout/mainContentLayout'
import IconDocument_list from '../../../icons/IconDocument_list'
import Image from 'next/image'
import IconFilm from '../../../icons/IconFilm'
import IconPlayFill from '../../../icons/IconPlayFill'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import htmlToText from '../../../../lib/htmlToText'
type itemsProps = {
  img?: string
  title?: string //
  type?: string
  body?: string //
  link?: string //
  typeLink?: string 
}[]
export default function BlogSection() {  
  const [displayedItems, setDisplayedItems] = useState<itemsProps>([
    {
      img: '/noimage.jpg',
      title: '',
      type: '',
      body: '',
      link:'#',
      typeLink:'#',
    },
    {
      img: '/noimage.jpg',
      title: '',
      type: '',
      body: '',
      link:'#',
      typeLink:'#',
    },
    {
      img: '/noimage.jpg',
      title: '',
      type: '',
      body: '',
      link:'#',
      typeLink:'#',
    },
  ])
  let items: itemsProps = [{}, {}, {}]
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 992)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    axios.get('https://rayancommerce.com/blog/wp-json/wp/v2/posts')
    .then((res) => {
      res.data.forEach((blog, inx) => {
        if(items[inx]){
          items[inx].title = blog.title.rendered
          items[inx].link = blog.link
          items[inx].body = htmlToText(blog.content.rendered).slice(0, 102) + '...'
          axios.get(blog._links["wp:featuredmedia"][0].href).then((media)=>{
            items[inx].img = media.data.source_url
            console.log(media.data.source_url)
          }).then(()=>{

            setDisplayedItems(isTablet ? items.slice(0, 1) : items.slice(0, 3))
          })
        }
      })
    })
    // .then(async (res) => {
    //   const newItems = await Promise.all(res.data.map(async (blog) => {
    //     let imgUrl = '/default-image.jpg'; // Fallback image
    //     if (blog._links["wp:featuredmedia"]) {
    //       const mediaRes = await axios.get(blog._links["wp:featuredmedia"][0].href);
    //       imgUrl = mediaRes.data.source_url;
    //     }
    //     return {
    //       img: imgUrl,
    //       title: blog.title.rendered,
    //       type: 'پوشش و آراستگی', // You can replace this with actual data if available
    //       body: htmlToText(blog.content.rendered).slice(0, 102) + '...',
    //       link: blog.link,
    //     };
    //   }));
    //   setDisplayedItems(newItems);
    // })
    .catch((error) => {
      console.error("Error fetching posts data:", error);
    });
  }, [])

  const blogArticle = 'https://ordme.com/blog/article'
  const blogVideos = 'https://ordme.com/blog/video/'
  const videos = [
    { img: '/blog/video1.jpg', content: 'به زیبایی خود اهمیت دهید' },
    { img: '/blog/video2.jpg', content: 'طراحی ناخن' },
  ]

  const [isTablet, setIsTablet] = useState<boolean>(false)


  return (
    <div className='w-full bg-light-80 box-border pb-24 pt-6 sm:px-6 992:px-0 max-md:pb-16 [&>*]:1170:!max-w-1170 [&>*]:!max-w-970 [&>*]:!mx-auto'>
      <div className='w-full flex flex-col gap-3 992:flex-row'>
        <div className='w-full 992:w-9/12'>
          <div className='h-12 w-full flex justify-between items-center mb-3 *:text-primary-500'>
            <Link href={blogArticle} className='text-sm'>
              <span className='flex items-center w-full'>
                <IconDocument_list width={46} height={46} />
                <h4 className='text-xl font-semibold'>در بلاگ اُردمی بخوانید</h4>
              </span> 
            </Link>
            <Link href={blogArticle} className='text-sm font-medium'>
              + مشاهده همه
            </Link>
          </div>
          <div className='grid gap-3 grid-cols-1 992:grid-cols-3'>
            {displayedItems.map((item, index) => (
              <div key={'blog-'+index} className='bg-gray-50 col-span-1 grid shadow-[0_1px_3px_rgba(0,0,0,0.2)] hover:shadow-[0_3px_6px_rgba(0,0,0,0.3)] transition-shadow duration-300 ease-in-out h-[475px] max-xl:h-auto max-992:w-auto'>
                <Link href={item.link??'#'} className='overflow-hidden transition-all duration-300 max-xl:min-h-[145px]'>
                  <img
                    width={280.16}
                    height={182.7}
                    src={item.img ?? ''}
                    alt={item.title ?? ''}
                    // layout='responsive'
                    className='brightness-[.85] hover:brightness-100 transition-all duration-500 ease-in-out object-cover w-full 992:max-h-36'
                  />
                </Link>
                <h2 className='p-2 font-medium text-lg transition-colors duration-300 ease-in-out hover:text-black mt-2 max-xl:h-[62px] max-xl:overflow-hidden max-md:max-h-[51px] max-xl:px-2 max-xl:p-0 max-xl:-h-[52px]'>
                  <Link href={item.link??'#'}>{item.title}</Link>
                </h2>
                <span className='text-[#6b6b6b] p-2 transition-colors duration-300 ease-in-out hover:text-[#b20b51] max-xl:px-4 max-xl:p-0 max-md:pb-[10px] max-md:pt-[15px]'>
                  <Link href={item.typeLink??'#'}>{item.type}</Link>
                </span>
                <p className='p-2 text-[15px] text-[#666] font-normal leading-8 max-xl:overflow-hidden max-xl:h-[90px] max-md:max-h-[90px] max-xl:p-0 max-xl:px-2'>
                  {item.body}
                </p>
                <div className='w-full flex justify-end p-2 transition-all duration-300 ease-in-out'>
                  <Button
                    sx={{
                      fontSize: '14px !important',
                      marginBottom: '8px',
                      marginLeft: '12px',
                      fontWeight: '400',
                      border: '1px #b20b51 solid',
                      width: '100px',
                      height: '35px',
                      backgroundColor: 'white',
                      borderRadius: '3px',
                      boxShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                      ':hover': {
                        background: '#b20b51',
                        color: 'white',
                      },
                    }}
                  >
                    ادامه مطلب
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='w-full 992:w-3/12 flex flex-col'>
          <div className='h-12 mb-3 w-full flex flex-row justify-between items-center text-primary-500'>
            <Link href={blogVideos} className='flex items-center font-semibold text-sm'>
              <IconFilm width={22} height={22} className='ml-2' />
              تازه‌های تصویری
            </Link>
            <Link href={blogVideos} className='text-sm font-medium'>
              + مشاهده همه
            </Link>
          </div>
          <div className='flex-1 flex gap-3 flex-col'>
            {videos.map((video, index) => (
              <div key={index} className='md:w-full md:h-1/2'>
                <div className='relative min-h-40 md:size-full flex flex-col shadow-sm hover:shadow-md transition-all overflow-hidden rounded-sm max-992:w-auto'>
                  <Link href={'#'} className='min-h-40 size-full relative'>
                    <Image fill src={video.img} alt='' className='object-cover' />
                  </Link>
                  <span className='bg-primary-500/70 w-12 h-12 flex justify-center items-center rounded-full absolute top-[calc(50%-1.5rem)] right-[calc(50%-1.5rem)]'>
                    <IconPlayFill width={32} height={32} className='text-white' />
                  </span>
                  <div className='absolute bottom-[2px] right-4 bg-opacity-60 text-white text-lg px-4 py-2 rounded font-medium'>
                    {video.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
